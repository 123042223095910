// 
// user.scss
// Use this to write your custom SCSS
//

.nav-pills .nav-link:hover {
    text-decoration: underline;
}

.top-search-bar {
    .form-control {
        width: 260px;
        &:focus {
            border-color: #2C7BE5;
        }
    }
    .input-group > * {
        background-color: #1E3A5C;
        border-color: #1E3A5C;
    }
    .search-results {
        padding: 1rem;
        margin-top: 1.25rem;
        width: 300px;
        max-height: 75vh;
        z-index: $zindex-tooltip;
    }
    .search-result:hover {
        background-color: #EDF2F9;
    }
}

#js-webform {
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

#webformSidenav {
    width: 250px;
    max-height: calc(100vh - 103px - 2.25rem);
    top: calc(103px + 2.25rem);
    scrollbar-width: thin;
    scrollbar-color: #999 $body-bg;
    .nav-link.active {
        color: $dark !important;
    }
}

.scroll-element {
    scroll-margin-top: calc(103px + 1.5rem);
}

.btn-collapse {
    .fe {
        transition: transform 0.2s ease-in-out;
    }
    &.collapsed .fe {
        transform: rotate(180deg);
    }
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.bg-whitesmoke {
    background-color: $whitesmoke;
}

.img-placeholder {
    width: 5rem;
    height: 5rem;
    background-color: #D9D9D9;
}

.img-placeholder-sm {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #D9D9D9;
}

.table-fixed {
    table-layout: fixed;
}

.link-default {
    @extend .link-dark;
    @extend .text-decoration-underline;
}

.btn-link-default {
    @extend .text-decoration-underline;
    @extend .p-0;
}

.feedback-form {
    width: 22vw;
    &::after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent $white transparent transparent;
    }
}

.clickup-form {
    &::after {
        content: " ";
        position: absolute;
        top: 100%;
        right: 40px;
        margin-right: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: $white transparent transparent transparent;
    }
}

.carousel-previous,
.carousel-next {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    padding: 0 0.5rem;
    .fe {
        font-size: 2rem;
        color: $white;
    }
    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.carousel-previous {
    left: -10%;
}

.carousel-next {
    right: -10%;
}

.tooltip-container {
    position: relative;
    &:hover .tooltip-text {
        visibility: visible;
    }
    .tooltip-text {
        visibility: hidden;
        position: absolute;
        width: max-content;
        max-width: 400px;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: $zindex-tooltip;
        font-size: $small-font-size;
        padding: 5px 10px;
        margin-bottom: 5px;
        &::after {
            content: " ";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
        }
        * {
            margin-bottom: 0;
        }
    }
    .tooltip-dark {
        background-color: $dark;
        color: $white;
        &::after {
            border-color: $dark transparent transparent transparent;
        }
    }
    .tooltip-light {
        background-color: $aliceblue;
        &::after {
            border-color: $aliceblue transparent transparent transparent;
        }
    }
    .tooltip-lg::after {
        margin-left: -10px;
        border-width: 10px;
    }
}

.table-default-hover > tbody > tr:hover {
    background-color: $whitesmoke;
    .hover-visible {
        visibility: visible !important;
    }
}

.zindex-tooltip {
    z-index: $zindex-tooltip;
}

.stepper {
    width: 1.25rem;
    height: 1.25rem;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-bg-success {
    background-color: #E1EFE9;
    color: #009054;
}

.text-bg-error {
    background-color: #f1bfbb;
    color: #860b02;
}