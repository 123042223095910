// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$offcanvas-horizontal-width: 450px;

$whitesmoke: #F5F5F5;
$aliceblue: #F0F8FF;